import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import DecimalInput from '../../../common/SimpleComponents/DecimalInput'
import { history } from '../../../helpers/history'
import i18n from '../../../../i18n'
import moment from 'moment'
import LbErrorMessage from '../../../common/SimpleComponents/LbErrorMessage'
import LbModal from '../../../common/Modal/LbModal'
import { LbLoader } from '../../../common/SimpleComponents/LBLoader'
import formatter from '../../../helpers/formatter'
import Icon from '../../../common/SimpleComponents/Icon'
import { currentTask as currentTaskActions } from '../../../redux/actions/corporate'
import * as currentTask from '../../../api/corporate/currentTask.api'
import * as batchCurrentTask from '../../../api/corporate/batchCurrentTask.api'
import SaveTemplateCorp from '../../../common/ComplexComponents/SaveTemplateCorp'
import AuthenticationHistoryModal from './AuthenticationHistoryModal'
import LBDateInput from '../../../common/SimpleComponents/LBDateInput'
import { LBDatePicker } from '../../../common/SimpleComponents/LBDatePicker'
import numberFormat from '../../../helpers/numberFormat'
import hasUserPermission, {
  corporateUserPermissions
} from '../../../helpers/hasUserPermission'
import userActions from '../../../redux/actions/user.actions'
import {
  canTransferToAccount,
  canTransferToBudget,
  canTransferToSomeone
} from './CreateTransferModal'
import LBCircularCountDown from '../../../common/SimpleComponents/LBCircularCountDown'
import ReactTooltip from 'react-tooltip'
import { LbToast } from '../../../common/SimpleComponents/LBToasts'
import { toast } from 'react-toastify'
import AuthorizationButtons from '../../../common/ComplexComponents/AuthorizationButtons'
import ReactCodeInput from '../../../common/SimpleComponents/ReactCodeInput'

import '../../../../assets/scss/component/transactions.scss'
import '../../../../assets/scss/corporateComponent/transactions.scss'
import Input from '../../../common/SimpleComponents/Input'
import { transferDescriptionValidation } from '../../../helpers/util'

const hasCancelPermission = () =>
  hasUserPermission(corporateUserPermissions.TRANSACTIONS_TASK_TERMINATION)
const canChangeFutureDate = () =>
  hasUserPermission(corporateUserPermissions.TRANSFERS_FUTURE_DATE_CHANGE)

const AUTHORIZE_MAX_ATTEMPS = 5

class CurrentTaskDetailsModal extends Component {
  initialState = {
    currentTaskIndex: null,
    lastSeenDetail: null, // while fetching/loading next task - show last seen + loader,
    prepareAuthError: false,
    smsResendAttempts: -1,
    showCancelationRow: false,
    submitting: false,
    reason: '',
    needTwoFactor: undefined,
    description: '',
    amount: '',
    editing: false,
    showFutureDate: false,
    smsError: false,
    printDetailsLoading: false,
    percentage: 0,
    totalMillis: 0,
    validationId: undefined,
    twoFactorType: undefined,
    touched: {}
  }

  state = this.initialState

  componentDidUpdate(prevProps, prevState) {
    const detail = this.props.currentTaskDetail
    let timer =
      (detail || {}).status === 'IsTemporary'
        ? detail.consentExpirationTime
        : (detail || {}).status === 'ToBeApproved'
          ? detail.expireCompletionTime
          : null
    if (
      detail &&
      detail.exchangetype === 'SpecialRate' &&
      timer &&
      this.props.currentTaskDetail !== prevProps.currentTaskDetail
    ) {
      clearInterval(this.timer)
      if (
        moment
          .unix(timer)
          .subtract(4, 'hours')
          .isAfter(moment())
      ) {
        this.setState({
          totalMillis: moment
            .unix(timer)
            .subtract(4, 'hours')
            .diff(moment())
        })

        this.timer = setInterval(() => {
          this.forceUpdate()
          if (
            moment
              .unix(timer)
              .subtract(4, 'hours')
              .isBefore(moment())
          ) {
            clearInterval(this.timer)
            this.detailsModal.handleCloseModal()
            this.onSuccess()
          }
        }, 1000)
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  printTaskDetails = id => {
    this.setState(
      prevState => ({
        ...prevState,
        printDetailsLoading: true
      }),
      () => {
        this.props
          .dispatch(currentTaskActions.fetchCurrentTaskDetails(id))
          .then(() => {
            this.setState(prevState => ({
              ...prevState,
              printDetailsLoading: false
            }))
          })
          .catch(() =>
            this.setState(prevState => ({
              ...prevState,
              printDetailsLoading: false
            }))
          )
      }
    )
  }

  fetchCurrentTaskDetails = id => {
    this.props.dispatch(currentTaskActions.fetchCurrentTaskDetail(id))
  }

  isOpened() {
    return this.detailsModal.isOpened()
  }

  open = data => {
    if (this.props.currentTaskModalId === data.id) return
    const currentTaskIndex = this.props.currentTasks.findIndex(
      x => x.id === data.id
    )

    this.props.dispatch(userActions.fetcbCorporateUserSettings())
    this.fetchCurrentTaskDetails(data.id)

    this.setState(
      {
        ...this.initialState,
        lastSeenDetail: null,
        currentTaskIndex
      },
      () => {
        this.props.dispatch(currentTaskActions.setCurrentTaskModalId(data.id))
        this.detailsModal.handleOpenModal()
      }
    )
  }
  modalCloseCallback = () => {
    this.props.dispatch(currentTaskActions.setCurrentTaskModalId(false))
    this.handleDetailsModalClose()
    if (this.props.onClose) this.props.onClose()
  }

  openById = id => {
    if (
      this.props.currentTaskModalId === id &&
      this.props.referer === 'specRate'
    )
      return
    this.fetchCurrentTaskDetails(id)
    this.setState(
      {
        ...this.initialState,
        lastSeenDetail: null,
        hideNavigation: true
      },
      () => {
        this.props.dispatch(currentTaskActions.setCurrentTaskModalId(id))
        this.detailsModal.handleOpenModal()
      }
    )
  }

  prevCurrentTask = () => {
    if (this.state.currentTaskIndex === 0) return

    const currentTaskIndex = this.state.currentTaskIndex - 1
    const currentTaskId = this.props.currentTasks[currentTaskIndex].id

    this.setState(
      {
        ...this.initialState,
        currentTaskIndex,
        lastSeenDetail: this.props.currentTaskDetail
      },
      () => {
        this.props.dispatch(
          currentTaskActions.setCurrentTaskModalId(currentTaskId)
        )
        this.fetchCurrentTaskDetails(currentTaskId)
      }
    )
  }

  nextCurrentTask = () => {
    if (this.state.currentTaskIndex >= this.props.currentTasks.length - 1)
      return

    const currentTaskIndex = this.state.currentTaskIndex + 1
    const currentTaskId = this.props.currentTasks[currentTaskIndex].id

    this.setState(
      {
        ...this.initialState,
        currentTaskIndex,
        lastSeenDetail: this.props.currentTaskDetail
      },
      () => {
        this.props.dispatch(
          currentTaskActions.setCurrentTaskModalId(currentTaskId)
        )
        this.fetchCurrentTaskDetails(currentTaskId)
      }
    )
  }

  // reset current task`s index and row`s active class
  handleDetailsModalClose = () => {
    this.setState({
      currentTaskIndex: null,
      lastSeenDetail: null
    })
  }

  cancelTask = () => {
    this.setState({ showCancelationRow: true })
  }

  startSubmit = () => this.setState({ submitting: true })

  stopSubmit = () => this.setState({ submitting: false })

  // do not need yet
  refetch = () => {
    this.setState(
      {
        ...this.initialState,
        currentTaskIndex: this.state.currentTaskIndex,
        lastSeenDetail: this.props.currentTaskDetail
      },
      () => this.fetchCurrentTaskDetails(this.props.currentTaskDetail.id)
    )
  }

  onSuccess = () => {
    if (this.props.onSuccess) {
      this.props.onSuccess(this.props.currentTaskDetail.id)
      toast.dismiss(this.props.currentTaskDetail.id)
    } else {
      toast.dismiss(this.props.currentTaskDetail.id)
    }
    this.props.dispatch(
      currentTaskActions.fetchCurrentTasks(this.props.currentTasksFilter)
    )
  }
  handleDelete = () => {
    this.startSubmit()
    this.warningModal.handleCloseModal()

    currentTask
      .remove(this.props.currentTaskDetail.id)
      .then(() => {
        this.detailsModal.handleCloseModal()
        this.onSuccess()
        LbToast('success', i18n.t('CurrentTasks.TaskDeleteSuccess'))
      })
      .catch(() => {
        LbToast('error', i18n.t('CurrentTasks.TaskDeleteFailed'))
      })
      .finally(() => {
        this.stopSubmit()
      })
  }

  resend = async () => {
    if (this.state.smsResendAttempts >= AUTHORIZE_MAX_ATTEMPS) return

    this.setState({ submitting: true })

    await currentTask
      .prepeareForAuthorization([this.props.currentTaskDetail.id])
      .then(
        ({ needTwoFactor, validationId, twoFactorType }) => {
          this.setState(
            state => ({
              smsResent: true,
              needTwoFactor,
              twoFactorType,
              validationId: validationId,
              smsCode: '',
              submitting: false,
              prepareAuthError: false,
              smsResendAttempts: state.smsResendAttempts + 1
            }),
            () => {
              if (needTwoFactor && twoFactorType !== 'Otp') {
                LbToast('dismiss')
                LbToast('warning', i18n.t('Transfers.PushNotificationSent'), {
                  autoClose: 20000,
                  closeButton: true,
                  hideProgressBar: true
                })
              }
            }
          )
        },
        error => {
          LbToast(
            'error',
            (error.errors && error.errors[0]) ||
            i18n.t('CurrentTasks.TaskAuthorizeFailed')
          )

          this.setState({ submitting: false, prepareAuthError: true })
        }
      )
  }

  authorize = () => {
    this.startSubmit()

    currentTask
      .authorize(
        this.props.currentTaskDetail.id,
        this.state.smsCode,
        this.state.validationId
      )
      .then(({ status }) => {
        if (
          !this.state.hideNavigation &&
          this.state.currentTaskIndex < this.props.currentTasks.length - 1 &&
          this.props.referer === 'specRate'
        ) {
          this.nextCurrentTask()
        } else if (
          !this.state.hideNavigation &&
          !!this.state.currentTaskIndex &&
          this.props.referer === 'specRate'
        ) {
          this.prevCurrentTask()
        } else {
          this.detailsModal.handleCloseModal()
        }
        this.onSuccess()

        if (status === 'ToBeApproved') {
          LbToast(
            'success',
            i18n.t('CurrentTasks.TaskAuthorizeSuccessWaitSign')
          )
        } else {
          LbToast('success', i18n.t('CurrentTasks.TaskAuthorizeSuccess'))
        }

        this.stopSubmit()
      })
      .catch(error => {
        if (error.code === 'OtpNotValidate') {
          this.setState({ smsError: true, smsCode: '' })
        } else if (error.code === 'MonthlyLimitExceeded') {
          this.errorModal.handleOpenModal()
        } else {
          LbToast(
            'error',
            i18n.t(
              `CurrentTasks.${error.code === 'InsufficientFund'
                ? 'InsufficientFund'
                : 'TaskAuthorizeFailed'
              }`
            )
          )

          this.onSuccess()

          if (
            error.code === 'LimitExhausted' ||
            error.code === 'InsufficientFund' ||
            error.code === 'AlreadyConfirmed'
          ) {
            this.detailsModal.handleCloseModal()
          } else {
            this.fetchCurrentTaskDetails(this.props.currentTaskDetail.id)
          }
        }

        this.stopSubmit()
      })
  }

  handleAuthorize = async () => {
    if (!this.state.needTwoFactor) {
      await this.resend()
      if (!this.state.needTwoFactor && !this.state.prepareAuthError)
        this.authorize()
    } else {
      this.authorize()
    }
  }

  handleDescriptionChangeAuthorize = () => {
    const { description } = this.state

    if (
      (!['ToSomeoneInternal', 'ToSomeoneExtrenal'].includes(
        this.props.currentTaskDetail.payType
      ) || this.props.currentTaskDetail.senderCurrency !== 'GEL')
    )
      return null

    return transferDescriptionValidation(description)
  }

  handleCancel = () => {
    this.startSubmit()
    this.warningModal.handleCloseModal()

    var cancelFn = this.props.fromBatch
      ? batchCurrentTask.cancel
      : currentTask.cancel

    cancelFn(this.props.currentTaskDetail.id, this.state.reason)
      .then(() => {
        this.detailsModal.handleCloseModal()
        LbToast('success', i18n.t('CurrentTasks.TaskCancelSuccess'))
        this.onSuccess()
      })
      .catch(() => {
        LbToast('error', i18n.t('CurrentTasks.TaskCancelFailed'))

        if (this.props.fromBatch) {
          this.detailsModal.handleCloseModal()
          this.onSuccess()
        }
      })
      .finally(() => {
        this.stopSubmit()
      })
  }

  handleConfirm = () => {
    this.startSubmit()

    const {
      id,
      description,
      additionalDescription
    } = this.props.currentTaskDetail

    currentTask
      .confirm(id, description, additionalDescription)
      .then(({ possibleToBeApproved, twoFactorType, status }) => {
        if (possibleToBeApproved) {
          this.fetchCurrentTaskDetails(id)
        } else {
          this.detailsModal.handleCloseModal()
          LbToast('success', i18n.t('CurrentTasks.TaskConfirmSuccess'))
        }
        if (
          possibleToBeApproved &&
          twoFactorType === 'None' &&
          status === 'ToBeApproved'
        )
          this.setState({ needTwoFactor: false, twoFactorType })
        this.stopSubmit()
        this.onSuccess()
      })
      .catch(err => {
        LbToast(
          'error',
          (err.errors && err.errors[0]) ||
          i18n.t('CurrentTasks.TaskConfirmFailed')
        )

        this.stopSubmit()
      })
  }

  handleChangeFutureDate = () => {
    const { id, futureDate } = this.props.currentTaskDetail

    if (this.state.showFutureDate) {
      this.setState({ submitting: true })
      currentTask
        .editFutureDate(
          id,
          moment(this.state.futureDate, 'DD/MM/YYYY')
            .startOf('day')
            .unix()
        )
        .then(() => {
          this.setState({
            editing: false,
            submitting: false,
            showFutureDate: false
          })
          LbToast('success', i18n.t('CurrentTasks.EditFutureDateSuccess'))

          this.fetchCurrentTaskDetails(id)
        })
        .catch(() => {
          this.setState({ submitting: false })
          LbToast('error', i18n.t('CurrentTasks.EditFutureDateFailed'))
        })
    } else {
      this.setState({
        showFutureDate: true,
        futureDate: moment.unix(futureDate).format('DD/MM/YYYY')
      })
    }
  }

  handleSmsChange = value => {
    this.setState({ smsCode: value })
  }

  copyLinks = {
    BetweenOwnAccount: '/transfer/toAccount',
    ToSomeoneInternal: '/transfer/toSomeone',
    ToSomeoneExtrenal: '/transfer/toSomeone',
    InBudget: '/transfer/trex',
    MobileTopup: '/payments/mobile',
    Billing: '/payments/utility',
    Penalty: '/payments/parking/oneTimePay'
  }

  handleCreateCopyClick = () => {
    const detail = this.props.currentTaskDetail
    this.detailsModal.handleCloseModal()
    history.push({
      pathname: this.copyLinks[detail.payType],
      state: {
        step:
          detail.payType === 'ToSomeoneExtrenal' &&
            ['GEL', 'RUB'].includes(detail.senderCurrency) === false &&
            !detail.receiverAddress
            ? 'newTemplate'
            : null,
        from: detail.senderAccountId,
        to: {
          accountId: detail.receiverAccountId || undefined,
          receiverIban: detail.receiverIban || undefined,
          receiverBankName: detail.receiverBankName || undefined,
          receiverCurrency: detail.senderCurrency || undefined,
          receiverSwiftCode: detail.receiverSwiftCode || undefined,
          receiverName: detail.receiverName || undefined,
          receiverAddress: detail.receiverAddress || undefined,
          receiverBankCountry: detail.receiverBankCountry || undefined,
          taxPayerName: detail.taxPayerName || undefined,
          taxPayerCode: detail.taxPayerCode || undefined,
          treasuryCode: detail.treasuryCode || undefined,
          intermediaryBankName: detail.intermediaryBankName || undefined,
          intermediarySwiftCode: detail.intermediarySwiftCode || undefined,
          transferType: detail.payType || undefined,
          description: detail.description || undefined,
          receiverINN: detail.receiverINN || undefined,
          receiverKPP: detail.receiverKPP || undefined,
          receiverBankAccount: detail.receiverBankAccount || undefined,
          receiverBankAddress: detail.receiverBankAddress || undefined,
          amount: detail.senderAmount || undefined,
          template: {
            treasuryFullCode: detail.treasuryCode || undefined,
            treasuryName: detail.description || undefined
          }
        },
        amount: detail.senderAmount || undefined
      }
    })
  }

  handleTemplateModalOpen = () => {
    this.templateModal.handleOpenModal()
    this.detailsModal.handleCloseModal()
  }

  handleTemplateModalClose = () => {
    this.templateModal.handleCloseModal()
    this.detailsModal.handleOpenModal()
  }

  handleHistoryModalOpen = () => {
    this.authHistoryModal.historyModalOpenHandler()
  }

  handleMakeChangeClick = () => {
    if (this.state.editing && (!this.state.amount || !this.state.description))
      return

    const {
      id,
      description,
      additionalDescription,
      senderAmount
    } = this.props.currentTaskDetail

    if (this.state.editing) {
      this.setState({ submitting: true })

      currentTask
        .edit(
          id,
          this.state.description,
          this.state.additionalDescription,
          this.state.amount
        )
        .then(() => {
          this.setState({
            editing: false,
            submitting: false
          })
          LbToast('success', i18n.t('CurrentTasks.TaskEditSuccess'))

          this.fetchCurrentTaskDetails(id)

          this.onSuccess()
        })
        .catch(() => {
          this.setState({ submitting: false })
          LbToast('error', i18n.t('CurrentTasks.TaskEditFailed'))
        })
    } else {
      this.setState({
        editing: true,
        description: description,
        additionalDescription: additionalDescription,
        amount: senderAmount
      })
    }
  }

  pickFutureDate(date) {
    this.setState({
      futureDate: date
    })
  }

  char_to_int = character => {
    switch (character) {
      case 'I':
        return 1
      case 'II':
        return 2
      case 'III':
        return 3
      case 'IV':
        return 4
      case 'V':
        return 5
      case 'VI':
        return 6
      case 'VII':
        return 7
      case 'VIII':
        return 8
      case 'IX':
        return 9
      case 'X':
        return 10
      default:
        return -1
    }
  }

  render() {
    const data = this.props.currentTaskDetail
      ? this.props.currentTaskDetail
      : this.state.lastSeenDetail

    const reasonForCancel = (data || {}).reasonForCancel
    // const reasonForCancel =
    //   this.props.currentTasks[this.state.currentTaskIndex] &&
    //   this.props.currentTasks[this.state.currentTaskIndex].reasonForCancel

    const isSpecialRate = data && data.exchangetype === 'SpecialRate'
    const exchangeRate =
      data && (isSpecialRate ? data.exchangeRate : data.currentExchangeRate)
    const viewRate =
      data && (isSpecialRate ? data.viewRate : data.currentViewRate)

    const hasOverflowingUserComment =
      !this.Userelement ||
      this.Userelement.offsetHeight < this.Userelement.scrollHeight ||
      this.Userelement.offsetWidth < this.Userelement.scrollWidth

    const hasOverflowingModuleComment =
      !this.element ||
      this.element.offsetHeight < this.element.scrollHeight ||
      this.element.offsetWidth < this.element.scrollWidth

    let timer =
      (data || {}).status === 'IsTemporary'
        ? data.consentExpirationTime
        : (data || {}).status === 'ToBeApproved'
          ? data.expireCompletionTime
          : null
    return (
      <Fragment>
        {/* Warning modal */}
        <LbModal
          ref={modal => {
            this.warningModal = modal
          }}
          header={i18n.t('Common.Warning')}
          className="mw-500"
          style={{ overlay: { zIndex: 12 } }}
        >
          <div className="lb-confirm-text fnt-15">
            <Trans
              i18nKey={
                data && data.status === 'IsTemporary' && !isSpecialRate
                  ? 'CurrentTasks.ConfirmDelete'
                  : 'CurrentTasks.ConfirmCancel'
              }
            />
          </div>

          <div className="lb-popup-footer">
            <button
              id="button-no"
              className="lb-btn btn-border btn-white"
              onClick={() =>
                this.setState(
                  {
                    showCancelationRow: false
                  },
                  () => this.warningModal.handleCloseModal()
                )
              }
            >
              <Trans i18nKey="Common.No" />
            </button>
            <button
              id="button-yes"
              className="lb-btn btn-red"
              onClick={
                data && data.status === 'IsTemporary' && !isSpecialRate
                  ? this.handleDelete
                  : this.handleCancel
              }
              disabled={this.state.submitting}
            >
              <Trans i18nKey="Common.Yes" />
            </button>
          </div>

          {this.state.submitting && (
            <LbLoader isLoading inCenter size="small" />
          )}
        </LbModal>

        <LbModal
          ref={modal => {
            this.dateModal = modal
          }}
          header={i18n.t('Common.SelectDate')}
          className="mw-auto wh-auto"
          style={{ overlay: { zIndex: 12 } }}
        >
          <LBDatePicker
            id={'futureTransferDatePicker'}
            style={{ width: '100%' }}
            inline
            dateFormat="DD/MM/YYYY"
            minDate={moment()
              .add(1, 'days')
              .toDate()}
            maxDate={moment()
              .add(6, 'months')
              .subtract(1, 'days')
              .toDate()}
            onChange={date => {
              this.setState({ futureDate: moment(date).format('DD/MM/YYYY') })
              this.dateModal.handleCloseModal()
            }}
          />
        </LbModal>

        <LbModal
          ref={modal => {
            this.detailsModal = modal
          }}
          header={i18n.t('Common.Details')}
          className="mw-750 lb-popup-slider current-tasks-slider"
          shouldCloseOnOverlayClick={false}
          modalClosed={this.handleDetailsModalClose}
          modalCloseCallback={() => this.modalCloseCallback()}
          style={{ overlay: { zIndex: 11 } }}
        >
          <div className="po-r slider-block">
            {!this.state.hideNavigation && !!this.state.currentTaskIndex && (
              <i
                id="prev-current-task"
                className="icn lb-slider-prev"
                onClick={this.prevCurrentTask}
              >
                <Icon name="icn-arrow-down" view="0 0 10.112 6" />
              </i>
            )}

            {!this.state.hideNavigation &&
              this.state.currentTaskIndex <
              this.props.currentTasks.length - 1 && (
                <i
                  id="next-current-task"
                  className="icn lb-slider-next"
                  onClick={this.nextCurrentTask}
                >
                  <Icon name="icn-arrow-down" view="0 0 10.112 6" />
                </i>
              )}

            {/* show last seen task Details while loading new one */}
            {!this.props.currentTaskDetailLoadingError &&
              ((this.props.currentTaskDetail &&
                !this.props.currentTaskDetailLoading) ||
                (this.state.lastSeenDetail &&
                  this.props.currentTaskDetailLoading)) && (
                <Fragment>
                  <div className="transaction-details flex">
                    <div className="transaction-details-row">
                      <div className="transaction-details-box" />

                      <div className="transaction-details-box">
                        {data.regDate && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="Common.Date" />:
                            </div>
                            <div className="transaction-details-col w-60">
                              {moment.unix(data.regDate).format('DD/MM/YYYY')}
                            </div>
                          </div>
                        )}

                        {data.futureDate && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.FutureDate" />:
                            </div>
                            <div className="transaction-details-col w-60">
                              {this.state.showFutureDate ? (
                                <div
                                  id="show-future-date"
                                  className="flex-aligned"
                                  onClick={() =>
                                    this.dateModal.handleOpenModal()
                                  }
                                >
                                  <LBDateInput
                                    disabled
                                    value={this.state.futureDate}
                                    icon
                                    minDate={
                                      new Date(
                                        new Date().getTime() +
                                        24 * 60 * 60 * 1000
                                      )
                                    }
                                    onChange={this.pickFutureDate}
                                  />
                                </div>
                              ) : (
                                moment
                                  .unix(data.futureDate)
                                  .format('DD/MM/YYYY')
                              )}
                            </div>
                          </div>
                        )}

                        {data.senderIban && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.FromAccount" />:
                            </div>
                            <div className="transaction-details-col w-60">
                              {data.senderIban}{' '}
                              {formatter.getCurrencySign(data.senderCurrency)}
                            </div>
                          </div>
                        )}

                        {data.receiverIban && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.ToAccount" />:
                            </div>
                            <div className="transaction-details-col w-60">
                              {data.receiverIban}{' '}
                              {formatter.getCurrencySign(data.receiverCurrency)}
                              {!!data.receiverName &&
                                (data.payType === 'ToSomeoneInternal' ||
                                  data.payType === 'ToSomeoneExtrenal') &&
                                ` / ${data.receiverName}`}
                            </div>
                          </div>
                        )}

                        {data.receiverBankCountry && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.Country" />:
                            </div>
                            <div className="transaction-details-col w-60">
                              {data.receiverBankCountry}
                            </div>
                          </div>
                        )}

                        {data.receiverBankName && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.ReceiverBank" />:
                            </div>
                            <div className="transaction-details-col w-60">
                              {data.receiverSwiftCode} / {data.receiverBankName}
                            </div>
                          </div>
                        )}

                        {data.intermediaryBankName && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.IntermediaryBank" />:
                            </div>
                            <div className="transaction-details-col w-60">
                              {data.intermediarySwiftCode} /{' '}
                              {data.intermediaryBankName}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="transaction-details-box">
                        {(data.senderAmount || data.senderAmount === 0) && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="Common.Amount" />:
                            </div>
                            <div className="transaction-details-col w-60">
                              {this.state.editing && !data.isConvert ? (
                                <div className="lb-input-out">
                                  <DecimalInput
                                    className="lb-input"
                                    wrapClassName="flex-1"
                                    value={this.state.amount}
                                    type="text"
                                    onChange={value => {
                                      this.setState({ amount: value })
                                    }}
                                    placeholder={i18n.t('Common.EnterAmount')}
                                    symbol={
                                      this.props.currentTaskDetail
                                        .senderCurrency
                                    }
                                    autoFocus
                                  />
                                </div>
                              ) : (
                                <>
                                  {numberFormat(
                                    isSpecialRate || !data.isConvert
                                      ? data.senderAmount || data.receiverAmount
                                      : data.currentSenderAmount
                                  )}{' '}
                                  {formatter.getCurrencySign(
                                    data.senderAmount
                                      ? data.senderCurrency
                                      : data.receiverCurrency
                                  )}
                                  {data.isConvert &&
                                    data.status !== 'WaitFxApproval' &&
                                    data.status !== 'Rejected' &&
                                    ` = ${numberFormat(
                                      isSpecialRate
                                        ? data.receiverAmount
                                        : data.currentReceiverAmount
                                    )} ${formatter.getCurrencySign(
                                      data.receiverCurrency
                                    )}`}
                                </>
                              )}
                            </div>
                          </div>
                        )}
                        {data.isConvert && !!exchangeRate && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.Course" />:
                            </div>
                            <div className="transaction-details-col w-60 fnt-b">
                              {exchangeRate > 1 ? (
                                <div>
                                  1{' '}
                                  {formatter.getCurrencySign(
                                    data.senderCurrency
                                  )}{' '}
                                  = {numberFormat(viewRate, 4)}{' '}
                                  {formatter.getCurrencySign(
                                    data.receiverCurrency
                                  )}
                                </div>
                              ) : (
                                <div>
                                  1{' '}
                                  {formatter.getCurrencySign(
                                    data.receiverCurrency
                                  )}{' '}
                                  = {numberFormat(viewRate, 4)}{' '}
                                  {formatter.getCurrencySign(
                                    data.senderCurrency
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {data.isConvert &&
                          isSpecialRate &&
                          data.status !== 'WaitFxApproval' &&
                          data.status !== 'Rejected' && (
                            <>
                              <div className="transaction-details-col-group">
                                <div className="transaction-details-col-lable w-40">
                                  <Trans i18nKey="CurrentTasks.YourWin" />:
                                </div>
                                <div className="transaction-details-col w-60 fnt-b">
                                  {numberFormat(
                                    data.amountCurrency === data.senderCurrency
                                      ? data.senderAmount *
                                      (data.exchangeRate -
                                        data.currentExchangeRate)
                                      : data.receiverAmount /
                                      data.currentExchangeRate -
                                      data.receiverAmount /
                                      data.exchangeRate,
                                    4
                                  )}{' '}
                                  {formatter.getCurrencySign(
                                    data.amountCurrency === data.senderCurrency
                                      ? data.receiverCurrency
                                      : data.senderCurrency
                                  )}
                                </div>
                              </div>
                            </>
                          )}

                        {(!!data.fee || data.fee === 0) && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.Fee" />:
                            </div>

                            <div className="transaction-details-col w-60">
                              {numberFormat(
                                data.guarFee
                                  ? data.fee + data.guarFee
                                  : data.fee
                              )}{' '}
                              {formatter.getCurrencySign(data.senderCurrency)}
                            </div>
                          </div>
                        )}

                        {!!data.chargeType && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.ChargeType" />:
                            </div>

                            <div className="transaction-details-col w-60">
                              {data.chargeType}
                            </div>
                          </div>
                        )}
                        {/* --------------------------------------------- */}
                        {(this.state.editing || data.description) && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.Purpose" />:
                            </div>

                            <div className="transaction-details-col w-60">
                              {this.state.editing ? (
                                <input
                                  type="text"
                                  className={`lb-input ${this.state.description.length <= 2 ||
                                    this.handleDescriptionChangeAuthorize() !== null
                                    ? 'has-error'
                                    : ''
                                    }`}
                                  autoFocus
                                  value={this.state.description}
                                  maxLength={127}
                                  onFocus={() => {
                                    this.setState({
                                      touched: {
                                        ...this.state.touched,
                                        [Input.key]: true
                                      }
                                    })
                                  }}
                                  onBlur={() => {
                                    this.setState({
                                      touched: {
                                        ...this.state.touched,
                                        [Input.key]: false
                                      }
                                    })
                                  }}
                                  onChange={e =>
                                    this.setState({
                                      description: e.target.value
                                    })
                                  }
                                />

                              ) : (
                                data.description
                              )}
                              {this.state.touched[Input.key] && (
                                <div className="fnt-12 fnt-low-m is-red mt-5">
                                  <Trans i18nKey={this.handleDescriptionChangeAuthorize()} />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {/* ------------------------ */}
                        {data.payType !== 'BetweenOwnAccount' &&
                          (this.state.editing ||
                            data.additionalDescription) && (
                            <div className="transaction-details-col-group">
                              <div className="transaction-details-col-lable w-40">
                                <Trans i18nKey="CurrentTasks.AdditionalDescription" />
                                :
                              </div>

                              <div className="transaction-details-col w-60">
                                {this.state.editing ? (
                                  <Input
                                    className="lb-input"
                                    autoFocus
                                    maxLength={127}
                                    value={this.state.additionalDescription}
                                    onChange={e =>
                                      this.setState({
                                        additionalDescription: e.target.value
                                      })
                                    }
                                    info={
                                      !['GEL', 'RUB'].includes(
                                        data.senderCurrency
                                      ) &&
                                        data.receiverSwiftCode !== 'LBRTGE22' &&
                                        hasUserPermission(true, false)
                                        ? i18n.t(
                                          'Payments.CorporateSwiftNotRubInfo'
                                        )
                                        : null
                                    }
                                  />
                                ) : (
                                  // <input
                                  //   type="text"
                                  //   className="lb-input"
                                  //   autoFocus
                                  //   maxLength={127}
                                  //   value={this.state.additionalDescription}
                                  //   onChange={e =>
                                  //     this.setState({
                                  //       additionalDescription: e.target.value
                                  //     })
                                  //   }
                                  // />
                                  data.additionalDescription
                                )}
                              </div>
                            </div>
                          )}

                        {data.transferFiles && data.transferFiles.length > 0 && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.AttachedFile" />:
                            </div>
                            <div className="transaction-details-col w-60">
                              {data.transferFiles.map((file, ind) => {
                                return (
                                  <div key={ind}>{file.originalFileName}</div>
                                )
                              })}
                            </div>
                          </div>
                        )}

                        {data.treasuryCode && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.TreasuryCode" />:
                            </div>

                            <div className="transaction-details-col w-60">
                              {data.treasuryCode}
                            </div>
                          </div>
                        )}

                        {(data.taxPayerCode || data.taxPayerDocNumber) && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.TaxPayerCode" />:
                            </div>

                            <div className="transaction-details-col w-60">
                              {data.taxPayerCode || data.taxPayerDocNumber}
                            </div>
                          </div>
                        )}

                        {data.taxPayerName && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.TaxPayerName" />:
                            </div>

                            <div className="transaction-details-col w-60">
                              {data.taxPayerName}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="transaction-details-box">
                        {data.status && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.Status" />:
                            </div>
                            <div className="transaction-details-col w-60">
                              {i18n.t(`CurrentTasks.${data.status}`)}
                            </div>
                          </div>
                        )}

                        {data.status === 'Rejected' && data.statusCode && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.RejectReason" />:
                            </div>
                            <div className="transaction-details-col w-60">
                              {reasonForCancel
                                ? reasonForCancel
                                : i18n.t(`CurrentTasks.${data.statusCode}`)}
                            </div>
                          </div>
                        )}

                        {data.createdBy && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.CreatedBy" />:
                            </div>
                            <div className="transaction-details-col w-60">
                              {data.createdBy}
                            </div>
                          </div>
                        )}

                        {data.awaitingSignRoles &&
                          data.status === 'ToBeApproved' && (
                            <div className="transaction-details-col-group">
                              <div className="transaction-details-col-lable w-40">
                                <Trans i18nKey="CurrentTasks.Waiting" />:
                              </div>
                              <div className="transaction-details-col w-60">
                                {data.awaitingSignRoles}{' '}
                                <Trans
                                  i18nKey={`${data.awaitingSignRoles === 'I'
                                    ? 'Administration.LevelSignature'
                                    : 'Administration.UpperLevelSignature'
                                    }`}
                                />
                              </div>
                            </div>
                          )}

                        {data.signedBy && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.SignedBy" />:
                            </div>
                            <div className="transaction-details-col w-60">
                              {data.signedBy}
                            </div>
                          </div>
                        )}

                        {data.status === 'Canceled' && data.canceledBy && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.CanceledBy" />:
                            </div>
                            <div className="transaction-details-col w-60">
                              {data.canceledBy}
                            </div>
                          </div>
                        )}

                        {data.status === 'Canceled' && reasonForCancel && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey="CurrentTasks.ReasonForCancel" />:
                            </div>
                            <div className="transaction-details-col w-60">
                              {reasonForCancel}
                            </div>
                          </div>
                        )}

                        {(data.payType === 'MobileTopup' ||
                          data.payType === 'Billing' ||
                          data.payType === 'Penalty') &&
                          data.providerName && (
                            <div className="transaction-details-col-group">
                              <div className="transaction-details-col-lable w-40">
                                <Trans
                                  i18nKey={`CurrentTasks.${data.payType === 'MobileTopup'
                                    ? 'MobileOperator'
                                    : 'ServiceName'
                                    }`}
                                />
                                :
                              </div>
                              <div className="transaction-details-col w-60">
                                {data.providerName}
                              </div>
                            </div>
                          )}

                        {data.payType === 'MobileTopup' && data.serviceName && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40">
                              <Trans i18nKey={'CurrentTasks.ServiceName'} />:
                            </div>
                            <div className="transaction-details-col w-60">
                              {data.serviceName}
                            </div>
                          </div>
                        )}

                        {(data.payType === 'MobileTopup' ||
                          data.payType === 'Billing' ||
                          data.payType === 'Penalty') &&
                          data.identifier && (
                            <div className="transaction-details-col-group">
                              <div className="transaction-details-col-lable w-40">
                                <Trans
                                  i18nKey={`CurrentTasks.${(data.payType ===
                                    'MobileTopup' &&
                                    'MobileNumber') ||
                                    (data.payType === 'Billing' &&
                                      'AbonentNumber') ||
                                    (data.payType === 'Penalty' &&
                                      'ReceiptNumber')}`}
                                />
                                :
                              </div>
                              <div className="transaction-details-col w-60">
                                {data.identifier}
                              </div>
                            </div>
                          )}

                        {data.userComment && (
                          <div className="transaction-details-col-group">
                            <div className="transaction-details-col-lable w-40 flex-aligned">
                              <Trans i18nKey="CurrentTasks.UserComment" />:
                              <i className="icn icn-chat ml-5">
                                <Icon name="icn-chat" viewBox="0 0 27 19.017" />
                              </i>
                            </div>

                            <div
                              className="transaction-details-col w-60 lb-ellipsis"
                              data-tip={true}
                              data-for={`div-UserComment-${data.id}`}
                              ref={element => {
                                this.Userelement = element
                              }}
                            >
                              {data.userComment}
                            </div>

                            {hasOverflowingUserComment &&
                              data.userComment &&
                              data.userComment.length > 50 && (
                                <ReactTooltip
                                  id={`div-UserComment-${data.id}`}
                                  className="lb-tooltip  type-darker"
                                  type="dark"
                                  effect="solid"
                                  place="top"
                                >
                                  <span className="fnt-low-m">
                                    {data.userComment}
                                  </span>
                                </ReactTooltip>
                              )}
                          </div>
                        )}
                        {data.moduleComment && (
                          <>
                            <div className="transaction-details-col-group">
                              <div className="transaction-details-col-lable w-40  flex-aligned">
                                <Trans i18nKey="CurrentTasks.ModuleComment" />:
                                <i className="icn icn-chat ml-5">
                                  <Icon
                                    name="icn-chat"
                                    viewBox="0 0 27 19.017"
                                  />
                                </i>
                              </div>

                              <div
                                className="transaction-details-col w-60 lb-ellipsis"
                                data-tip={true}
                                data-for={`div-ModuleComment-${data.id}`}
                                ref={element => {
                                  this.element = element
                                }}
                              >
                                {data.moduleComment}
                              </div>

                              {hasOverflowingModuleComment &&
                                data.moduleComment &&
                                data.moduleComment.length > 50 && (
                                  <ReactTooltip
                                    id={`div-ModuleComment-${data.id}`}
                                    className="lb-tooltip  type-darker"
                                    type="dark"
                                    effect="solid"
                                    place="top"
                                  >
                                    <span className="fnt-low-m">
                                      {data.moduleComment}
                                    </span>
                                  </ReactTooltip>
                                )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {data.isConvert &&
                    !isSpecialRate &&
                    ['ToBeApproved', 'Future', 'IsTemporary'].includes(
                      data.status
                    ) &&
                    data.currentExchangeRate !== data.exchangeRate && (
                      <div className="transaction-details-warning">
                        <div className="is-warrning flex-aligned">
                          <i className="icn icn-warning-new mr-5">
                            <Icon
                              name="icn-warning-new"
                              view="0 0 33.658 33.658"
                            />
                          </i>

                          <div className="pl-10 fnt-12">
                            <Trans i18nKey="CurrentTasks.RateHasBeenChanged" />
                            <br />
                            <Trans i18nKey="CurrentTasks.OldRate" />:{' '}
                            {data.exchangeRate > 1 ? (
                              <Fragment>
                                1{' '}
                                {formatter.getCurrencySign(data.senderCurrency)}{' '}
                                = {numberFormat(data.viewRate, 4)}{' '}
                                {formatter.getCurrencySign(
                                  data.receiverCurrency
                                )}
                              </Fragment>
                            ) : (
                              <Fragment>
                                1{' '}
                                {formatter.getCurrencySign(
                                  data.receiverCurrency
                                )}{' '}
                                = {numberFormat(data.viewRate, 4)}{' '}
                                {formatter.getCurrencySign(data.senderCurrency)}
                              </Fragment>
                            )}
                            <br />
                            <Trans i18nKey="CurrentTasks.OldAmount" />:{' '}
                            {data.exchangeRate < 1 ? (
                              <Fragment>
                                {data.senderAmount}{' '}
                                {formatter.getCurrencySign(data.senderCurrency)}{' '}
                                = {data.receiverAmount}{' '}
                                {formatter.getCurrencySign(
                                  data.receiverCurrency
                                )}
                              </Fragment>
                            ) : (
                              <Fragment>
                                {data.receiverAmount}{' '}
                                {formatter.getCurrencySign(
                                  data.receiverCurrency
                                )}{' '}
                                = {data.senderAmount}{' '}
                                {formatter.getCurrencySign(data.senderCurrency)}
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                  <div className="">
                    {isSpecialRate &&
                      timer &&
                      moment
                        .unix(timer)
                        .subtract(4, 'hours')
                        .isAfter(moment()) && (
                        <div className="flex-aligned progrees-c">
                          <LBCircularCountDown
                            strokeWidth="1"
                            sqSize="10"
                            percentage={Math.trunc(
                              ((this.state.totalMillis -
                                moment
                                  .unix(timer)
                                  .subtract(4, 'hours')
                                  .diff(moment())) /
                                this.state.totalMillis) *
                              100
                            )}
                          />
                          <div className="is-red fnt-low fnt-25 ml-15 w-10">
                            {moment(
                              moment
                                .unix(timer)
                                .subtract(4, 'hours')
                                .diff(moment())
                            ).format('mm:ss')}
                          </div>

                          <div className="fnt-low fnt-13 ml-40 flex-1">
                            <Trans
                              i18nKey={
                                (data || {}).status === 'ToBeApproved'
                                  ? 'CurrentTasks.AuthorisationTimerText'
                                  : 'CurrentTasks.ApprovalTimerText'
                              }
                            />
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="transaction-details-links flex-aligned mt-25">
                    {(data.payType === 'ToSomeoneInternal' ||
                      data.payType === 'ToSomeoneExtrenal' ||
                      data.payType === 'InBudget') && (
                        <div className="btn-link">
                          <span
                            id="saved-by-template"
                            className="underline"
                            onClick={this.handleTemplateModalOpen}
                          >
                            <Trans i18nKey="CurrentTasks.SavedByTemplate" />
                          </span>
                        </div>
                      )}
                    {data.status !== 'IsTemporary' &&
                      data.status !== 'WaitFxApproval' && (
                        <div className="btn-link">
                          <span
                            id="authentication-history"
                            className="underline"
                            onClick={this.handleHistoryModalOpen}
                          >
                            <Trans i18nKey="CurrentTasks.AuthenticationHistory" />
                          </span>
                        </div>
                      )}

                    {(data.status === 'Future' ||
                      (data.status === 'ToBeApproved' && data.isFuture)) &&
                      !this.state.showFutureDate &&
                      canChangeFutureDate() && (
                        <div className="btn-link">
                          <span
                            id="change-transaction-date"
                            className="underline"
                            onClick={this.handleChangeFutureDate}
                          >
                            <Trans i18nKey="CurrentTasks.ChangeTransactionDate" />
                          </span>
                        </div>
                      )}

                    {/* TODO: პრინტის და გამეორების ღილაკები ზემოთ */}
                    <div className="flex-aligned ml-auto">
                      {data.status !== 'Completed' &&
                        data.status !== 'WaitFxApproval' ? (
                        <div
                          id="print-task-details"
                          className="lb-trans-info mb-5 mr-70 pointer flex-aligned"
                          onClick={() => {
                            this.printTaskDetails(data.id)
                          }}
                        >
                          <div className="fnt-13 fnt-low-m flex-aligned po-r">
                            {this.state.printDetailsLoading && (
                              <LbLoader isLoading inCenter size="xxs" />
                            )}
                            <i className="icn icn-create-copy ">
                              <Icon
                                name="icn-print-new"
                                viewBox="0 0 20.44 20.114"
                              />
                            </i>
                          </div>
                          <span className="pl-10 fnt-low-m">
                            <Trans i18nKey="Common.Print" />
                          </span>
                        </div>
                      ) : null}

                      {((data.payType === 'BetweenOwnAccount' &&
                        canTransferToAccount()) ||
                        ((data.payType === 'ToSomeoneInternal' ||
                          data.payType === 'ToSomeoneExtrenal') &&
                          canTransferToSomeone()) ||
                        (data.payType === 'InBudget' &&
                          canTransferToBudget())) && (
                          <div>
                            {!(
                              ['WaitFxApproval', 'ToBeApproved'].includes(
                                data.status
                              ) && isSpecialRate
                            ) && (
                                <div
                                  id="create-copy"
                                  className="lb-trans-info ml-10 mb-5 mr-70 pointer"
                                  onClick={this.handleCreateCopyClick}
                                >
                                  <div className="fnt-13 fnt-low-m flex-aligned">
                                    <i className="icn icn-create-copy mr-10">
                                      <Icon
                                        name="icn-create-copy"
                                        view="0 0 13.58 9.263"
                                      />
                                    </i>
                                    <span>
                                      <Trans i18nKey="CurrentTasks.CreateCopy" />
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="lb-popup-footer flex-justify-end">
                    {data.status === 'IsTemporary' &&
                      !this.state.showCancelationRow ? (
                      <Fragment>
                        <div className="lb-trans-info mb-5 flex-aligned">
                          {!this.state.editing && (
                            <>
                              {!data.isConvert && (
                                <div
                                  id="make-change"
                                  className="fnt-13 fnt-upper-m flex-aligned mr-30 icn-link"
                                  onClick={this.handleMakeChangeClick}
                                >
                                  <i className="icn icn-bg icn-pen mr-5">
                                    <Icon
                                      name="icn-pen"
                                      view="0 0 16.272 10.812"
                                    />
                                  </i>
                                  <span>
                                    <Trans i18nKey="CurrentTasks.MakeChange" />
                                  </span>
                                </div>
                              )}

                              {!isSpecialRate && (
                                <div
                                  id="delete"
                                  className="fnt-13 fnt-upper-m flex-aligned icn-link"
                                  onClick={() =>
                                    this.warningModal.handleOpenModal()
                                  }
                                >
                                  <i className="icn icn-bg icn-trash mr-5">
                                    <Icon
                                      name="icn-trash"
                                      view="0 0 10.534 12.177"
                                    />
                                  </i>
                                  <span>
                                    <Trans i18nKey="Common.Delete" />
                                  </span>
                                </div>
                              )}
                            </>
                          )}
                        </div>

                        <div className="fnt-14 fnt-upper-m">
                          {this.state.editing ? (
                            <button
                              id="save"
                              className="lb-btn btn-red"
                              onClick={this.handleMakeChangeClick}
                              // disabled={this.state.submitting}
                              disabled={
                                this.state.description.length <= 2 ||
                                this.state.submitting || this.handleDescriptionChangeAuthorize() !== null
                              }
                            >
                              <Trans i18nKey="CurrentTasks.Save" />
                            </button>
                          ) : (
                            <div className="flex-aligned">
                              {this.props.currentTaskDetail.status !==
                                'IsTemporary' ||
                                (this.props.currentTaskDetail.status ===
                                  'IsTemporary' &&
                                  isSpecialRate) ? (
                                <button
                                  id="cancel-task"
                                  className="lb-btn btn-red"
                                  onClick={() => this.cancelTask()}
                                  disabled={this.state.submitting}
                                >
                                  <Trans i18nKey="CurrentTasks.Cancel" />
                                </button>
                              ) : null}
                              <button
                                id="confirmation"
                                className="lb-btn btn-red"
                                onClick={this.handleConfirm}
                                disabled={this.state.submitting}
                              >
                                <Trans i18nKey="CurrentTasks.Confirmation" />
                              </button>
                            </div>
                          )}
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        {this.state.showCancelationRow ? (
                          <Fragment>
                            <div className="fnt-14 fnt-low-m details-canceled-reason">
                              <span>
                                <Trans i18nKey="CurrentTasks.CancelationReason" />
                              </span>

                              <input
                                id="input-Canceled-Reason"
                                type="text"
                                className="lb-input lb-input-grey mt-5"
                                placeholder={i18n.t(
                                  'CurrentTasks.CancelationReason'
                                )}
                                value={this.state.reason}
                                maxLength={100}
                                onChange={e =>
                                  this.setState({ reason: e.target.value })
                                }
                              />
                            </div>

                            <button
                              id="button-cancel"
                              className="lb-btn btn-red mt-15"
                              onClick={() =>
                                this.warningModal.handleOpenModal()
                              }
                              disabled={this.state.submitting}
                            >
                              <Trans i18nKey="CurrentTasks.Cancel" />
                            </button>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <div className="fnt-14 fnt-upper-m flex-space ml-auto">
                              {this.state.showFutureDate ? (
                                <button
                                  id="save"
                                  className="lb-btn btn-red"
                                  onClick={this.handleChangeFutureDate}
                                  disabled={this.state.submitting}
                                >
                                  <Trans i18nKey="CurrentTasks.Save" />
                                </button>
                              ) : (
                                <>
                                  {this.state.needTwoFactor &&
                                    this.state.twoFactorType === 'Otp' && (
                                      <div className="flex mr-15">
                                        <div className="mr-20">
                                          <div>
                                            <div className="fnt-upper-m fnt-14 mb-5 text-left">
                                              <Trans i18nKey="Transfer.SmsCode" />
                                            </div>
                                          </div>
                                          <div
                                            id="div-requestSms-TranferConfirmation"
                                            className="flex-aligned is-red fnt-low-b fnt-14 pointer"
                                            onClick={this.resend}
                                          >
                                            <span className="send-code">
                                              <Trans i18nKey="Common.NewSmsCode" />
                                            </span>
                                            <i className="icn icn-13 ml-10 ">
                                              <Icon
                                                name="icn-arrow-repeat"
                                                view="0 0 12 13.216"
                                              />
                                            </i>
                                          </div>
                                        </div>

                                        <div>
                                          {data.status === 'ToBeApproved' &&
                                            this.state.twoFactorType ===
                                            'Otp' && (
                                              <ReactCodeInput
                                                type="number"
                                                fields={4}
                                                className={`${this.props.smsError
                                                  ? 'sms-input-error'
                                                  : ''
                                                  }`}
                                                value={this.state.smsCode}
                                                onChange={this.handleSmsChange}
                                              />
                                            )}
                                          {this.state.smsError && (
                                            <div className="code-error mt-5">
                                              <i className="icn icn-warning">
                                                <Icon
                                                  name="icn-warning"
                                                  view="0 0 12.026 14"
                                                />
                                              </i>
                                              <Trans i18nKey="Common.SMSCodeIncorrect" />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  <AuthorizationButtons
                                    isDisabled={
                                      this.state.submitting ||
                                      (this.state.twoFactorType === 'Otp' &&
                                        (!this.state.smsCode ||
                                          this.state.smsCode.length < 4))
                                    }
                                    loading={this.state.submitting}
                                    cancelButton={
                                      !this.state.showFutureDate &&
                                        ((data.status === 'Pending' &&
                                          data.status === 'Created') ||
                                          data.status === 'Future' ||
                                          (data.status === 'ToBeApproved' &&
                                            !isSpecialRate)) &&
                                        hasCancelPermission()
                                        ? {
                                          label: i18n.t(
                                            'CurrentTasks.Cancel'
                                          ),
                                          handler: () => this.cancelTask()
                                        }
                                        : null
                                    }
                                    submitHandler={
                                      data.status === 'ToBeApproved' &&
                                        this.props.currentTaskDetail.signatures.every(
                                          s =>
                                            !s.userId ||
                                            s.userId.toUpperCase() !==
                                            this.props.userId.toUpperCase()
                                        ) &&
                                        (this.props.corporateSettings
                                          .isAdministrator ||
                                          (!!this.props.corporateSettings
                                            .authLevel &&
                                            this.props.currentTaskDetail.signatures.some(
                                              s =>
                                                !s.userId &&
                                                this.char_to_int(
                                                  s.authLevelName
                                                ) >=
                                                this.char_to_int(
                                                  this.props.corporateSettings
                                                    .authLevel
                                                )
                                            )))
                                        ? this.handleAuthorize
                                        : null
                                    }
                                    submitButton={
                                      data.status === 'ToBeApproved' &&
                                        this.props.currentTaskDetail.signatures.every(
                                          s =>
                                            !s.userId ||
                                            s.userId.toUpperCase() !==
                                            this.props.userId.toUpperCase()
                                        ) &&
                                        (this.props.corporateSettings
                                          .isAdministrator ||
                                          (!!this.props.corporateSettings
                                            .authLevel &&
                                            this.props.currentTaskDetail.signatures.some(
                                              s =>
                                                !s.userId &&
                                                this.char_to_int(
                                                  s.authLevelName
                                                ) >=
                                                this.char_to_int(
                                                  this.props.corporateSettings
                                                    .authLevel
                                                )
                                            )))
                                        ? {
                                          label: i18n.t(
                                            'CurrentTasks.ToAssign'
                                          )
                                        }
                                        : null
                                    }
                                    twoFactorType={this.state.twoFactorType}
                                    resendTwoFactorCode={this.resend}
                                    validationId={this.state.validationId}
                                    fromPage={'currentTasks'}
                                  />
                                </>
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Fragment>
                    )}

                    {data.status === 'Editable' && (
                      <Fragment>
                        <div className="lb-trans-info mb-5 flex-aligned">
                          <div className="fnt-13 fnt-upper-m flex-aligned mr-30 icn-link">
                            <i className="icn icn-bg icn-pen mr-5">
                              <Icon name="icn-pen" view="0 0 16.272 10.812" />
                            </i>
                            <span>
                              <Trans i18nKey="CurrentTasks.MakeChange" />
                            </span>
                          </div>
                          <div className="fnt-13 fnt-upper-m flex-aligned icn-link">
                            <i className="icn icn-bg icn-trash mr-5">
                              <Icon name="icn-trash" view="0 0 10.534 12.177" />
                            </i>
                            <span>
                              <Trans i18nKey="Common.Delete" />
                            </span>
                          </div>
                        </div>
                        <div className="fnt-14 fnt-upper-m">
                          <button className="lb-btn btn-red">
                            <Trans i18nKey="CurrentTasks.Confirmation" />
                          </button>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </Fragment>
              )}

            <LbLoader
              isLoading={this.props.currentTaskDetailLoading}
              inCenter
              size="small"
            />
            {this.props.currentTaskDetailLoadingError && (
              <LbErrorMessage message="error" type="modal" />
            )}
          </div>
          {this.state.submitting && (
            <LbLoader isLoading inCenter size="small" />
          )}
        </LbModal>

        <LbModal
          ref={modal => {
            this.errorModal = modal
          }}
          customCloseEvent={() => this.errorModal.handleCloseModal()}
          header={i18n.t('Common.Message')}
          className="mw-500"
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
        >
          <div className="lb-confirm-text">
            <Trans i18nKey="SecurityLevel.LimitExceededWarning" />
            <span
              className="is-red underline pl-5 pointer"
              onClick={() => history.push('/settings/securitylevel')}
            >
              {' '}
              <Trans i18nKey="SecurityLevel.SecurityLevel" />
            </span>
          </div>
          <div className="lb-popup-footer">
            <input
              id={'input-TransferContainer'}
              type="button"
              className="lb-btn btn-red btn-confirm"
              value={i18n.t('Common.Close')}
              onClick={() => this.errorModal.handleCloseModal()}
            />
          </div>
        </LbModal>

        {/* save template modal */}
        <LbModal
          ref={modal => {
            this.templateModal = modal
          }}
          header={i18n.t('CurrentTasks.SaveTemplate')}
          className="mw-700 save-template-modal"
          blockBodyClass="po-r"
        >
          <SaveTemplateCorp
            handleModalClose={this.handleTemplateModalClose}
            currentTask={data}
          />
        </LbModal>

        {data && (
          <AuthenticationHistoryModal
            ref={history => (this.authHistoryModal = history)}
            currentTask={data}
          />
        )}
      </Fragment>
    )
  }
}

export default connect(
  (state, props) => {
    const { corporate } = state

    return {
      currentTasks: props.currentTasks
        ? props.currentTasks
        : corporate.currentTasks,
      currentTaskDetail: props.predefinedStatus
        ? { ...corporate.currentTaskDetail, status: props.predefinedStatus }
        : corporate.currentTaskDetail,
      currentTaskModalId: corporate.currentTaskModalId,
      currentTaskDetailLoading: corporate.currentTaskDetailLoading,
      currentTaskDetailLoadingError: corporate.currentTaskDetailLoadingError,
      userId: state.oidc.user.profile.sub,
      corporateSettings: state.userSettings.corporateSettings,
      currentTasksFilter: corporate.currentTasksFilter
    }
  },
  null,
  null,
  { forwardRef: true }
)(CurrentTaskDetailsModal)

import moment from 'moment'
import { downloadFileRequest, jsonRequest } from '../helpers/apiRequest'
import { baseUrl } from '../helpers/baseUrl'
import { getUrlWithQueryString } from '../helpers/util'

let transcationEndPoint = 'transaction/v2'

export let setTranscationEndPoint = endPoint => {
  //transcationEndPoint = endPoint
}

export let getTranscationEndPoint = () => {
  return 'elastictransaction' // transcationEndPoint
}

export function fetchPaginatedStatementsAjax(
  accountId = null,
  startDate = moment().subtract(6, 'months'),
  endDate = moment(),
  searchId = null,
  IsEquivalent = null,
  takeNextCount = 30,
  signal = null
) {
  startDate = moment(startDate)
    .startOf('day')
    .unix()
  endDate = moment(endDate)
    .endOf('day')
    .unix()

  const params = {
    accountId,
    startDate,
    endDate,
    ...(searchId && { searchId }),
    takeNextCount: takeNextCount
  }
  //
  if (IsEquivalent) params.IsEquivalent = IsEquivalent

  let url = getUrlWithQueryString(
    `${baseUrl}/${transcationEndPoint}/statements/paged`,
    params
  )

  return jsonRequest(
    url,
    true,
    undefined,
    'GET',
    { internalServerError: false, badRequest: false },
    signal
  )
}

export function fetchStatementsAjax(
  accountId = null,
  startDate = moment().subtract(6, 'months'),
  endDate = moment(),
  signal = null
) {
  startDate = moment(startDate)
    // .local()
    .startOf('day')
    .unix()

  endDate = moment(endDate)
    // .local()
    .endOf('day')
    .unix()

  const url = accountId
    ? `${baseUrl}/${transcationEndPoint}/statements?accountId=${accountId}&StartDate=${startDate}&EndDate=${endDate}`
    : `${baseUrl}/${transcationEndPoint}/statements?StartDate=${startDate}&EndDate=${endDate}`
  return jsonRequest(
    url,
    true,
    undefined,
    'GET',
    { internalServerError: false, badRequest: false },
    signal
  )
}

export function exportStatements(
  account,
  startDate = moment().subtract(6, 'months'),
  endDate = moment(),
  format
) {
  if (!account) {
    return
  }
  startDate = moment(startDate)
    // .local()
    .startOf('day')
    .unix()
  endDate = moment(endDate)
    // .local()
    .endOf('day')
    .unix()

  const url = `${baseUrl}/${transcationEndPoint}/statements/export/${
    format === 'xls' ? 'excel' : format
  }?AccountId=${account}&StartDate=${startDate}&EndDate=${endDate}`

  return downloadFileRequest(url, 'AccountStatements', format, true)
}

export function exportStatementsAsync(
  account,
  startDate = moment().subtract(1, 'months'),
  endDate = moment(),
  format
) {
  if (!account) {
    return
  }
  startDate = moment(startDate)
    .startOf('day')
    .unix()
  endDate = moment(endDate)
    .endOf('day')
    .unix()

  const url = `${baseUrl}/${transcationEndPoint}/statements/export/${
    format === 'xls' ? 'excel' : format
  }-async?AccountId=${account}&StartDate=${startDate}&EndDate=${endDate}`

  return jsonRequest(url, true, undefined, 'GET')
}

export function checkStatementsFileStatus(fileId) {
  const url = `${baseUrl}/${transcationEndPoint}/statements/export/check-file-status?FileId=${fileId}`

  return jsonRequest(url, true, undefined, 'GET')
}

export function getStatementsFile(fileId, format) {
  const url = `${baseUrl}/${transcationEndPoint}/statements/export/get-file?FileId=${fileId}`

  return downloadFileRequest(url, 'AccountStatements', format, true)
}
export const fetchMonthlyTurnoverAjax = (startDate, endDate) => {
  // eslint-disable-next-line max-len
  let url = `${baseUrl}/${transcationEndPoint}/finanncial-summary/monthly-turnover?startDate=${startDate}&endDate=${endDate}`
  return jsonRequest(url, true)
}
export const fetchCurrentTurnoverState = () => {
  let url = `${baseUrl}/${transcationEndPoint}/finanncial-summary/state`
  return jsonRequest(url, true)
}
export function fetchTransactionsAjax(
  {
    iban,
    keyword,
    skip = 0,
    take = 30,
    loadMoreTransactions = false,
    startDate,
    endDate,
    currency,
    amountFrom,
    amountTo,
    categoryId,
    subcategoryId,
    type
  },
  signal = null
) {
  const params = {}
  if (iban) params.iban = iban
  if (currency) params.currency = currency

  if (keyword) params.keyword = keyword

  if (skip) params.skip = skip
  if (categoryId) params.categoryId = categoryId
  if (subcategoryId) params.subcategoryId = subcategoryId
  if (take) params.take = loadMoreTransactions ? take : take

  if (startDate) params.startDate = startDate.startOf('day').unix()
  if (endDate) params.endDate = endDate.endOf('day').unix()

  if (amountFrom) params.fromAmount = amountFrom
  if (amountTo) params.toAmount = amountTo
  if (type) params.type = type

  let url = getUrlWithQueryString(
    `${baseUrl}/${transcationEndPoint}/transactions`,
    params
  )
  return jsonRequest(
    url,
    true,
    undefined,
    'GET',
    { internalServerError: false, badRequest: false },
    signal
  )
}

export function exportTransactions({
  iban,
  keyword,
  startDate,
  endDate,
  currency,
  amountFrom,
  amountTo,
  type
}) {
  const params = {}
  if (iban) params.iban = iban
  if (currency) params.currency = currency
  if (keyword) params.keyword = keyword
  if (startDate) params.startDate = startDate.startOf('day').unix()
  if (endDate) params.endDate = endDate.endOf('day').unix()

  if (amountFrom) params.fromAmount = amountFrom
  if (amountTo) params.toAmount = amountTo
  if (type) params.type = type

  let url = getUrlWithQueryString(
    `${baseUrl}/${transcationEndPoint}/transactions/export/excel`,
    params
  )
  return downloadFileRequest(url, 'Transactions', 'xls', true)
}

export function fetchGetBlockedAmountAjax(iban) {
  var url = iban
    ? `${baseUrl}/${transcationEndPoint}/blocked-amounts?iban=${iban}`
    : `${baseUrl}/${transcationEndPoint}/blocked-amounts`
  return jsonRequest(url, true)
}

export function fetchGetOtherBlockedAmountAjax(accountId) {
  var url = accountId
    ? `${baseUrl}/${transcationEndPoint}/blocked-amounts/other?accountId=${accountId}`
    : `${baseUrl}/${transcationEndPoint}/blocked-amounts/other`
  return jsonRequest(url, true)
}

export function fetchGetBlockedAmountByCardIdAjax(cardId) {
  var url = `${baseUrl}/${transcationEndPoint}/blocked-amounts/${encodeURIComponent(
    cardId
  )}`
  return jsonRequest(url, true)
}

export function fetchTransactionsDetailsAjax(id, transactionDate, routingKey) {
  let url = `${baseUrl}/${transcationEndPoint}/transactions/details?id=${id}&TransactionDate=${transactionDate}&RoutingKey=${routingKey}`
  return jsonRequest(url, true)
}

export function changeFinancialCategory({
  id,
  transactionDate,
  categoryId,
  subcategoryId
}) {
  let url = `${baseUrl}/${transcationEndPoint}/finanncial-summary/change-financial-category`
  return jsonRequest(
    url,
    true,
    {
      transactionId: id,
      transactionDate,
      categoryId,
      subcategoryId
    },
    'POST'
  )
}

export function changeIncludeinSummary(id, transactionDate, includeInSummary) {
  let url = `${baseUrl}/${transcationEndPoint}/finanncial-summary/change-include-in-summary`
  return jsonRequest(
    url,
    true,
    {
      transactionId: id,
      transactionDate,
      includeInSummary
    },
    'POST'
  )
}

export function fetchBlockedAmmountProductsCommissionAjax() {
  return jsonRequest(
    `${baseUrl}/${transcationEndPoint}/blocked-amounts/product/commission`,
    true
  )
}

export function fetchCurrentTasksAjax({
  keyword,
  skip = 0,
  take = 100,
  startDate,
  endDate,
  status = []
}) {
  const params = {}
  if (keyword) params.keyword = keyword
  if (skip) params.skip = skip
  if (take) params.take = take + 1
  if (startDate) params.startDate = startDate.startOf('day').unix()
  if (endDate) params.endDate = endDate.endOf('day').unix()
  if (status) params.status = status

  let url = getUrlWithQueryString(
    `${baseUrl}/currenttask/v1/current-task`,
    params
  )
  return jsonRequest(url, true)
}

export function fetchCurrentTaskDetailsAjax(id) {
  var url = `${baseUrl}/currenttask/v1/current-task/details?id=${id}`
  return jsonRequest(url, true)
}

export function downloadSwiftCode(id, transactionDate) {
  if (!id || !transactionDate) {
    return
  }
  const url = `${baseUrl}/${transcationEndPoint}/transactions/swift-trasnfer-data-pdf?id=${id}&TransactionDate=${transactionDate}`
  return downloadFileRequest(url, 'swift', 'pdf', true)
}

export function exportTransactionDetails(id, transactionDate) {
  if (!id || !transactionDate) {
    return
  }
  const url = `${baseUrl}/${transcationEndPoint}/transactions/export/pdf?id=${id}&TransactionDate=${transactionDate}`
  return downloadFileRequest(url, `Transaction_Details_${id}`, 'pdf', true)
}

export function fetchTransactionFinancialSummaryAjax({
  startDate = moment().startOf('month'),
  endDate = moment(),
  includeAggregation = false,
  isIncome = false,
  categoryId = '',
  categoryName = '',
  subcategoryId = [],
  searchId = '',
  takeNextCount = 100
}) {
  startDate = moment(startDate)
    .startOf('day')
    .unix()
  endDate = moment(endDate)
    .endOf('day')
    .unix()

  const params = {
    startDate,
    endDate,
    includeAggregation,
    isIncome,
    categoryId,
    categoryName,
    subcategoryId,
    searchId: searchId ? searchId : '',
    takeNextCount: takeNextCount
  }

  let url = getUrlWithQueryString(
    `${baseUrl}/${transcationEndPoint}/finanncial-summary`,
    params
  )

  return jsonRequest(url, true, undefined, 'GET', {
    internalServerError: false,
    badRequest: false
  })
}

export const fetchTransactionCategoryListAjax = () => {
  let url = `${baseUrl}/${transcationEndPoint}/finanncial-summary/category-list`
  return jsonRequest(url, true, undefined, 'GET', {
    internalServerError: false,
    badRequest: false
  })
}

export function getDetailsFile(iban, fileName) {
  const url = `${baseUrl}/account/v4/debit/details/${iban}/interest/pdf`
  return downloadFileRequest(url, fileName, 'pdf', true)
}
